<template>
  <div class="container">
    <section class="py-4 login__form">
      <div class="container">
        <div class="row justify-content-center">
          <div class="col-12 col-md-6 col-lg-5 d-flex flex-column ">
            <div class="py-4">
              <router-link to="/" class="logo w-auto d-block mx-auto">
                <img src="../assets/images/logo__metaLearning__ch.png" alt="" width="160">
              </router-link>
            </div>
            <div class="card login__card">
              <div class="card-body">
                <div class="pt-4 pb-2">
                    <h5 class="card-title text-center pb-0 fs-4">登入</h5>
                    <p class="text-center small login__txt mb-3">
                      歡迎您來到本學院。
                    </p>
                </div>
                <ValidationObserver v-slot="{ handleSubmit }" >
                  <form class="row login__formContent" @submit.prevent="handleSubmit(login)">
                    <div class="col-12 mt-3">
                      <label for="Username" class="form-label text-start d-inline-block w-100">使用者名稱</label>
                      <ValidationProvider rules='required|username' v-slot='{ errors , classes }'>
                        <div :class="classes">
                          <input
                            type="text"
                            name="username"
                            class="form-control"
                            id="Username"
                            required
                            v-model="user.username"
                            data-test="name">
                          <span class="text-danger mt-1" data-test="nameError">{{ errors[0] }}</span>
                        </div>
                      </ValidationProvider>
                    </div>
                    <div class="col-12 mt-3">
                      <label for="Password" class="form-label text-start d-inline-block w-100">密碼</label>
                      <ValidationProvider rules='password' v-slot='{ errors , classes }'>
                        <div :class="classes" class="position-relative">
                          <input
                            name="Password"
                            class="form-control"
                            id="Password"
                            required
                            v-model="user.password"
                            :type="showPsd ? 'text' : 'password'"
                            data-test="password">
                          <span class="text-danger mt-1" data-test="psdError">{{ errors[0] }}</span>
                          <i class="fas fa-eye psd_visible position-absolute"
                            @click.prevent="showPsd=true" v-if="!showPsd" data-test="password-visible"></i>
                          <i class="fas fa-eye-slash psd_invisible position-absolute"
                            @click.prevent="showPsd=false" v-if="showPsd" data-test="password-invisible"></i>
                        </div>
                      </ValidationProvider>
                    </div>
                    <div class="col-12 mt-3 d-flex">
                      <input class="form-check-input me-2" type="checkbox" value="" id="rememberMe" v-model="rememberMe" @change="toggleRemember">
                      <label class="form-check-label mt-1" for="rememberMe">
                        記住我
                      </label>
                    </div>
                    <div class="col-12 mt-3">
                      <button class="btn btn-main w-100" type="submit">登入</button>
                    </div>
                    <div class="col-12 mt-3">
                        <p class="small mb-0">還沒有帳號？
                          <router-link to="/register" style="color: #012970;">註冊</router-link> |
                          <router-link to="/forgetPsd" style="color: #012970;">忘記密碼</router-link>
                          </p>
                      </div>
                  </form>
                </ValidationObserver>
                <GoogleSignInButton  class="mt-3"/>
              </div>
            </div>

          </div>
        </div>
      </div>
    </section>
    <Meta :title ="title"  />
  </div>
</template>

<script>
import jwt from 'jwt-decode';
import jwtDecode from 'jwt-decode';
import { decode } from 'punycode';
import { mapActions, mapGetters } from 'vuex';
import GoogleSignInButton from '../components/GoogleSigninButton';

const Swal = require('sweetalert2');

export default {
  name: 'Login',
  data() {
    return {
      user: {
        username: '',
        password: '',
      },
      rememberMe: false,
      showPsd: false,
      title: '登入 - 元學堂',
    };
  },
  metaInfo: {
    title: "登入 - 元學堂",
    meta: [
      { name: 'description', content: '博碩文化數位影音教學平台，以專業的師資教材，提供您全方位的影音學習環境，突破傳統的學習模式。' },
      {
        property: 'og:title',
        content: '登入 - 元學堂',
      },
      {
        property: 'og:description',
        content: '博碩文化數位影音教學平台，以專業的師資教材，提供您全方位的影音學習環境，突破傳統的學習模式。',
      },
    ]
  },
  components: {
    GoogleSignInButton,
  },
  methods: {
    toggleRemember() {
      localStorage.setItem('rememberMe', this.rememberMe);
      if (this.rememberMe) {
        localStorage.setItem('DrUsername', this.user.username);
      }
    },
    login() {
      // connect to api
      this.updateLoading(true);
      const user = {
        UserName: this.user.username,
        Password: this.user.password,
      };
      this.$log.debug(user);
      this.$http.post(`${this.$API_PATH}/Member/Login`, user, {
        headers: {
          Accept: '*/*',
          'Content-Type': 'application/json',
        },
      }).then((res) => {
        this.$log.debug(res.data);
        const decoded = jwtDecode(res.data.data.jwtToken);

        this.$log.debug(decoded);
        this.$store.dispatch('getUser', decoded);
        this.$store.dispatch('getUserAuth', res.data.data.jwtToken);
        Swal.fire({
          title: '登入成功',
          icon: 'success',
          timer: 1500,
        });
        this.updateLoading(false);

        // push to login page
        // 1650980606
        this.$router.push('/');
      }).catch((err) => {
        this.$log.debug(err);
        if (err.response) {
          this.updateLoading(false);
          this.$log.debug(err.response.status);
          this.$log.debug(err.response);
          if (err.response.status == 400) {
            Swal.fire(
              '',
              `${err.response.data.message}`,
              'info',
            );
          }
        } else {
          this.updateLoading(false);
          Swal.fire(
            '錯誤',
            '請稍候再試',
            'info',
          );
        }
      });
    },
    ...mapActions(['updateLoading', 'getUserAuth', 'getUserFromLocal', 'getUserAuthFromLocal', 'checkExpAuth']),
  },
  computed: {
    ...mapGetters(['userAuth', 'userState']),
  },
  created() {
    this.rememberMe = localStorage.getItem('rememberMe') || false;
    if (this.rememberMe) {
      this.user.username = localStorage.getItem('DrUsername');
    }
    this.getUserFromLocal();
    this.getUserAuthFromLocal();
    this.checkExpAuth();
    this.$log.debug(this.userState);
    if (this.userAuth.length !== 0 && this.userState.length !== 0) {
      this.$router.push('/');
    }
  },
};
</script>

<style lang="scss" scoped>
  .invalid .text-danger {
    text-align: left;
    width: 100%;
    display: block;
    font-size: 12px;
  }

  .invalid input {
    border: 1px #EB0600 solid;
    &:focus {
      box-shadow: 0 0 0 0.25rem rgba(252, 67, 21, 0.25);
    }
  }
</style>
